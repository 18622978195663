import React, { FC } from 'react'

import ROUTES from '~/utils/constants/routes'

import LogoIcon from '~/assets/icons/logo.svg'

import Link from './Link'

interface LogoProps {
  isCakerHome?: boolean
}

const Logo: FC<LogoProps> = ({ isCakerHome }) => {
  return (
    <Link
      href={ROUTES.home}
      sx={{
        path: { fill: isCakerHome ? '#fff' : 'primary.main' }
      }}
      display="flex"
      aria-label="CakerHQ"
    >
      <LogoIcon style={{ cursor: 'pointer' }} />
    </Link>
  )
}

export default Logo
