import { useRouter } from 'next/router'
import React, { FC } from 'react'

import { Button, ButtonProps } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import ROUTES from '~/utils/constants/routes'

interface CakeMakersButtonProps extends ButtonProps {}

const CakeMakersButton: FC<CakeMakersButtonProps> = ({ sx, ...props }) => {
  const router = useRouter()

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={() => router.push(ROUTES.professionals)}
      sx={{ fontWeight: 600, ...sx }}
      {...props}
    >
      <FormattedMessage defaultMessage="For Cake Makers" />
    </Button>
  )
}

export default CakeMakersButton
