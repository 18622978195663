import { makeSxStyles } from '~/utils/helpers/makeSxStyles'

export const useStyles = makeSxStyles(() => {
  return {
    container: { maxWidth: '1890px', display: { xs: 'none', lg: 'block' } },
    navigationLink: {
      fontSize: 15,
      fontWeight: 600,

      '&:hover': {
        color: 'primary.main'
      }
    },
    cakeMakersButton: { ml: 3, width: 155 }
  }
})
