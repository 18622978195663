import React, { FC } from 'react'

import { AppBar, Toolbar } from '@mui/material'

import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

interface HeaderProps {
  isCakerHome?: boolean
  isSearch?: boolean
}

const Header: FC<HeaderProps> = ({ isCakerHome, isSearch }) => {
  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      sx={{
        bgcolor: 'default',
        mt: { xs: 0, lg: 1.5 },
        zIndex: 2
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          minHeight: { lg: 64 },
          pl: { lg: 1.5 },
          pr: { lg: 1.5 }
        }}
      >
        <HeaderMobile />
        <HeaderDesktop isCakerHome={isCakerHome} isSearch={isSearch} />
      </Toolbar>
    </AppBar>
  )
}

export default Header
