import dynamic from 'next/dynamic'
import React, { FC } from 'react'

import { Container, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import Link from '~/components/base/Link'
import Logo from '~/components/base/Logo'
import CakeMakersButton from '~/components/buttons/CakeMakersButton'

import useAuth from '~/services/Auth/useAuth'
import ROUTES from '~/utils/constants/routes'

import { useStyles } from './Header.styles'
import { useHeaderLinks } from './links.config'

const AccountPopover = dynamic(
  () => import('~/components/popovers/AccountPopover'),
  { ssr: false }
)

interface HeaderDesktopProps {
  isCakerHome?: boolean
  isSearch?: boolean
}

const HeaderDesktop: FC<HeaderDesktopProps> = ({ isCakerHome, isSearch }) => {
  const styles = useStyles()
  const navLinks = useHeaderLinks()
  const auth = useAuth()
  const isLogged = auth.selectors.isLogged()
  const isCakeMaker = auth.selectors.isCakeMaker()
  const maxWidth = isSearch ? '1920px' : 'lg'
  const color = isCakerHome ? 'background.default' : 'text.primary'

  return (
    <Container
      maxWidth={maxWidth as any}
      disableGutters={isSearch}
      sx={styles.container}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Logo isCakerHome={isCakerHome} />

          <Stack direction="row" spacing={3.5} ml={6}>
            {navLinks.map(({ label, to }) => {
              const isCalculator = to === ROUTES.calculator
              const currentColor = isCalculator ? 'third.main' : color

              return (
                <Link
                  prefetch={false}
                  key={label}
                  href={to}
                  color={currentColor}
                  sx={styles.navigationLink}
                >
                  {label}
                </Link>
              )
            })}
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center">
          {isLogged ? (
            <AccountPopover />
          ) : (
            <Link
              href={ROUTES.chooseRole}
              prefetch={false}
              color={color}
              sx={styles.navigationLink}
            >
              <FormattedMessage defaultMessage="Sign in" />
            </Link>
          )}

          {!isCakeMaker && <CakeMakersButton sx={styles.cakeMakersButton} />}
        </Stack>
      </Stack>
    </Container>
  )
}

export default HeaderDesktop
