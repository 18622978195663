import { defineMessages } from 'react-intl'

const msg = defineMessages({
  findCakeMaker: {
    defaultMessage: 'Find a Cake Maker'
  },
  aboutUs: {
    defaultMessage: 'About us'
  },
  blog: {
    defaultMessage: 'Blog'
  },
  signin: {
    defaultMessage: 'Sign in'
  },
  forProfessionals: {
    defaultMessage: 'For professionals'
  },
  calculator: {
    defaultMessage: 'Calculator'
  }
})

export default msg
