const ROUTES = {
  home: '/',
  professionals: '/professionals',

  // auth
  signIn: '/sign-in',
  signInCakeMaker: '/caker/sign-in',
  signUp: '/sign-up',
  signUpCakeMaker: '/caker/sign-up',
  signUpVerify: '/verify',
  confirmationEmail: '/email-confirmation',
  forgotPassword: '/forgot-password',
  forgotPasswordVerify: '/forgot-password/successful',
  resetPassword: '/reset-password',
  resetPasswordVerify: '/reset-password/successful',
  chooseRole: '/choose-role',

  // profile
  profile: '/professional-account',
  details: '/profile/details',
  calendar: '/profile/calendar',
  requests: '/profile/requests',
  payment: '/profile/requests/payment',
  chat: '/profile/chat',
  orders: '/profile/orders',
  review: '/profile/reviews',
  settings: '/profile/settings',
  favourites: '/profile/favourites',
  dashboard: '/profile/dashboard',

  search: '/search',
  media: '/media',
  subscription: '/subscription',
  calculator: '/calculator',

  // static pages
  terms: '/terms',
  privacy: '/privacy',
  aboutUs: '/about-us',
  cancellation: '/cancellation-policy',
  subscribeToUpdates: '/subscribe-to-updates',
  cakeMakerGuide: '/cake-maker-guide'
}

export default ROUTES
