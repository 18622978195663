import { useIntl } from 'react-intl'

import ROUTES from '~/utils/constants/routes'

import msg from './Header.messages'

interface NavLinkOption {
  label: string
  to: string
}

export const useHeaderLinks = (): NavLinkOption[] => {
  const { formatMessage } = useIntl()

  const navLinks = [
    {
      label: formatMessage(msg.findCakeMaker),
      to: ROUTES.search
    },
    {
      label: formatMessage(msg.aboutUs),
      to: ROUTES.aboutUs
    },
    {
      label: formatMessage(msg.blog),
      to: ROUTES.media
    },
    {
      label: formatMessage(msg.calculator),
      to: ROUTES.calculator
    }
  ]

  return navLinks
}
