import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { FC, useCallback, useEffect, useState } from 'react'

import { Box, Grid, IconButton } from '@mui/material'

import Logo from '~/components/base/Logo'

import useAuth from '~/services/Auth/useAuth'

import MenuIcon from '~/assets/icons/header/menu.svg'
import NotifyIcon from '~/assets/icons/notify.svg'

const Sidebar = dynamic(() => import('../Sidebar'), { ssr: false })

const HeaderMobile: FC = () => {
  const router = useRouter()
  const [isOpenSidebar, setSidebar] = useState<boolean>(false)
  const { user } = useAuth()

  useEffect(() => {
    router.events.on('routeChangeStart', () => setSidebar(false))
  }, [])

  const handleClose = useCallback(() => setSidebar((prev) => !prev), [])

  return (
    <>
      <Grid
        container
        sx={{
          py: '15px',
          display: { xs: 'flex', lg: 'none' }
        }}
        justifyContent="center"
      >
        <Grid item xs={6}>
          <Logo />
        </Grid>

        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <MenuIcon />

            {user?.is_notification && (
              <Box sx={{ position: 'absolute', right: 2, bottom: 12 }}>
                <NotifyIcon />
              </Box>
            )}
          </IconButton>
        </Grid>
      </Grid>

      <Sidebar open={isOpenSidebar} onChange={handleClose} />
    </>
  )
}

export default HeaderMobile
